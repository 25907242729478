<template>
    <div class="expirados-index-page">
        <main class="expirados-index-page__main">
            <section class="main-section">
                <h1 class="main-section__title">{{ $lang.pages.expirados.lost }}</h1>
                <p class="main-section__description">{{ $lang.pages.expirados.expired_recently }}</p>
                <ul v-if="discounts.length" class="main-section__list-expired">
                    <li v-for="(item, key) in discounts" :key="key" class="main-section__article">
                        <DCardMain :info="item" :expire="true" />
                    </li>
                </ul>
                <MiscNoResults v-else mode="discounts" :title="$lang.pages.expirados.no_discounts_expired" />
                <ButtonLoadMore v-if="fetchButton.show" :loading="fetchButton.loading" :handler="fetch" />
            </section>
            <section class="search-section">
                <h2 class="search-section__title">
                    {{ $lang.pages.expirados.found_discounts }}
                </h2>
                <ul class="search-section__cards">
                    <li v-for="search of searchMore" :key="search.title" class="search-section__card">
                        <img
                            v-if="search.ribbon"
                            :src="search.ribbon"
                            class="search-section__ribbon"
                            width="90px"
                            height="30px"
                        />
                        <NuxtLink no-prefetch :to="search.path">
                            <img :src="search.image" class="search-section__card-image" />
                            <div class="card-content">
                                <p class="card-content__title">
                                    {{ search.title }}
                                </p>
                                <img :src="$assets.gray.arrowGrayRight" alt="icon" />
                            </div>
                        </NuxtLink>
                    </li>
                </ul>
            </section>
        </main>
        <aside class="expirados-index-page__sidebar">
            <LayoutGalleryTwo v-if="categories" :info="categories" :path="'/' + $lang.routes.categories" />
            <LayoutGallery
                v-if="stores"
                :title="$lang.pages.expirados.featured_stores"
                :path="`/${$lang.routes.brands}`"
                :info="stores"
            />
        </aside>
    </div>
</template>

<script lang="ts" setup>
import { useRootStore } from '~/store/root'
import type { Models } from '~/types/models'

import { replacePlaceholders } from '~/util/textFunction'
import type { Api } from '~~/global'

import { useDateFunctions } from '~/composables/DateFunctions'

const { currentDate } = useDateFunctions()

type SearchMore = {
    title: string
    image: string
    path: string
    ribbon?: string
}
/* Responses.General.IndividualModel<Models.Discount */
const { $lang, $assets } = useNuxtApp()
const { buildHeaders, baseURL, endpoints } = useApiConfig()

const getDiscounts = (params?: any) =>
    $fetch<Api.Responses.General.IndividualModel<Models.Discount>>(endpoints.general.discounts.path, {
        headers: buildHeaders(),
        method: 'GET',
        baseURL,
        params: {
            status: 'expired',
            order_by: 'latest',
            ...params,
        },
    })

const { data: responseData, error } = await useAsyncData('expired-discounts-data', () => {
    return getDiscounts().catch((e) => e.data)
})

if (error.value || !responseData.value || responseData.value.error) {
    throw createError({
        statusCode: responseData.value?.feedback === 'resource_not_found' ? 404 : 500,
        message:
            responseData.value?.feedback === 'resource_not_found' ? 'Página no encontrada' : 'Algo salió mal',
    })
}

const discounts: Ref<Models.Discount[]> = ref(responseData.value?.data?.results || [])

const searchMore: SearchMore[] = [
    {
        title: $lang.pages.expirados.coupons,
        image: $assets.illustration.megaCoupons,
        path: `/${$lang.routes.coupons}`,
    },
    {
        title: $lang.pages.expirados.offers,
        image: $assets.illustration.megaOfertas,
        path: `/${$lang.routes.offers}`,
    },

    {
        title: $lang.pages.expirados.categories,
        image: $assets.illustration.megaCategories,
        path: `/${$lang.routes.categories}`,
    },
    {
        title: $lang.pages.expirados.brands,
        image: $assets.illustration.megaBrands,
        path: `/${$lang.routes.brands}`,
    },
    {
        title: $lang.pages.expirados.register,
        image: $assets.illustration.megaCommunity,
        ribbon: $assets.misc.ribbonRegister,
        path: `/${$lang.routes.register}`,
    },
]
const fetchButton = reactive({
    loading: false,
    show:
        (responseData.value?.data.meta.total || 0) > 0 &&
        (responseData.value?.data.meta.current_page || 0) < (responseData.value?.data.meta.last_page || 0),
})

const params = reactive({
    per_page: 10,
    page: 2,
    order_by: 'latest',
})

const tabsInfo = reactive({
    show: 0,
    tabs: [
        { title: $lang.pages.expirados.popular, amount: 8 },
        { title: $lang.pages.expirados.news, amount: 2 },
        { title: $lang.pages.expirados.commented, amount: 3 },
    ],
})
const RootStore = useRootStore()

const categories = computed(() => {
    return RootStore.layoutData?.data.sidebar?.categories || null
})
const stores = computed(() => {
    return RootStore.layoutData?.data.sidebar?.stores || null
})

const fetch = async () => {
    fetchButton.loading = true

    const { feedback, data } = await getDiscounts(params)
    if (feedback === 'data_success') {
        discounts.value = [...discounts.value, ...data.results]

        if (data.meta.current_page < data.meta.last_page) {
            params.page = data.meta.current_page + 1
        } else {
            fetchButton.show = false
        }
    }

    fetchButton.loading = false
}
const currentDates = currentDate()

const [description] = replacePlaceholders(
    [
        [':month', currentDates.month],
        [':year', String(currentDates.year)],
    ],
    [$lang.pages.expirados.all_coupons],
)

const Route = useRoute()
const {
    public: { origin },
} = useRuntimeConfig()

useSeoMeta({
    title: $lang.pages.expirados.expired_recently,
    ogUrl: origin + Route.path,
    ogTitle: $lang.pages.expirados.expired_recently,
    description: description,
    ogDescription: description,
    ogImageAlt: $lang.pages.expirados.expired_recently,
    twitterCard: 'summary_large_image',
})

useHead({
    link: [
        {
            rel: 'canonical',
            href: origin + Route.path,
        },
    ],
})
</script>

<style lang="postcss" scoped>
.expirados-index-page {
    @apply container mb-8 xl:flex;
    &__main {
        @apply xl:order-2 xl:ml-5 xl:flex-grow;
        .main-section {
            @apply mt-5 rounded-2xl bg-white px-2 py-3;
            &__title {
                @apply text-2xl font-semibold lg:text-3xl;
            }
            &__description {
                @apply text-base text-site-primary lg:text-xl;
            }
            &__list-expired {
                @apply mb-5 space-y-3;
            }
            &__article {
                @apply mt-4;
            }
            &__microtabs {
                @apply block lg:hidden;
            }
        }
        .search-section {
            @apply mt-5;
            &__title {
                @apply text-xl font-semibold;
            }
            &__cards {
                @apply mt-4 grid grid-cols-2 gap-2 md:grid-cols-3 md:gap-6;
            }
            &__ribbon {
                @apply absolute top-2;
                left: -3px;
            }
            &__card {
                @apply relative rounded-xl bg-white duration-100 ease-in;
                &:hover {
                    box-shadow: 0px 4px 50px rgba(54, 18, 73, 0.21);
                }
                &-image {
                    @apply h-40 w-full rounded-t-xl object-cover object-left;
                }
                .card-content {
                    @apply flex w-full justify-between p-4;
                    &__title {
                        @apply font-medium;
                    }
                }
            }
        }
    }
    &__sidebar {
        @apply mt-5 space-y-5 xl:order-1 xl:w-80 xl:flex-none;
    }
}
</style>
